import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import store from '@/store'
import { ISmsTemplate, SmsTemplateItemState } from './types'
import { createSmsTemplate, fetchSmsTemplate, updateSmsTemplate } from '@/api/smsTemplates'

// eslint-disable-next-line import/prefer-default-export
export const appSmsTemplate = createModule(
  'appSmsTemplate',
  {
    namespaced: true,
    state: {
      isLoading: false,
      smsTemplate: {
        name: '',
        body: '',
      },
      isButtonDisabled: false,
      lastCursorPosition: 0,
    } as SmsTemplateItemState,
    getters: {
      getIsLoading: state => state.isLoading,
      getSmsTemplate: state => state.smsTemplate,
      getIsButtonDisabled: state => state.isButtonDisabled,
      getLastCursorPosition: state => state.lastCursorPosition,
    },
    mutations: {
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_SMS_TEMPLATE(state, val: ISmsTemplate) {
        state.smsTemplate = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      SET_LAST_CURSOR_POSITION(state, val: number) {
        state.lastCursorPosition = val
      },
      INIT_SMS_TEMPLATE(state) {
        state.smsTemplate = {
          name: '',
          body: '',
        }
      },
      INIT_STATE(state) {
        state.isLoading = false
        state.smsTemplate = {
          name: '',
          body: '',
        }
        state.isButtonDisabled = false
        state.lastCursorPosition = 0
      },
    },
    actions: {
      async createSmsTemplate({ state }) {
        try {
          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(true)
          const response = await createSmsTemplate(state.smsTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'New SMS template was successfully created',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
          return response
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error creating SMS template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return error
        } finally {
          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
      async fetchSmsTemplate(ctx, id) {
        try {
          appSmsTemplate.mutations.SET_IS_LOADING(true)
          const response = await fetchSmsTemplate(id)
          appSmsTemplate.mutations.SET_SMS_TEMPLATE(response.data.data)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching SMS template item',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appSmsTemplate.mutations.SET_IS_LOADING(false)
        }
      },
      async updateSmsTemplate({ state }) {
        try {
          const updatedSmsTemplate = {
            id: state.smsTemplate.id,
            name: state.smsTemplate.name,
            body: state.smsTemplate.body,
          }

          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(true)

          await updateSmsTemplate(updatedSmsTemplate)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'SMS Template was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating SMS Template',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appSmsTemplate.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
    },
  },
)

appSmsTemplate.register(store)
