



































































































import Vue from 'vue'

import {
  BOverlay,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BLink,
  BButton,
  BvEvent,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import AvailablePlaceholder from '@/views/components/available-placeholder/AvailablePlaceholder.vue'
import { appSmsTemplate } from '@/store/modules/app-sms-templates/app-sms-template-item'
import { permissionSubjects } from '@/libs/acl/constants'

export default Vue.extend({
  name: 'SmsTemplateItem',
  components: {
    BOverlay,
    BCard,
    BForm,
    BLink,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    AvailablePlaceholder,
  },
  directives: {
    Ripple,
  },
  computed: {
    isLoading() {
      return appSmsTemplate.getters.getIsLoading
    },
    smsTemplate() {
      return appSmsTemplate.getters.getSmsTemplate
    },
    isButtonDisabled() {
      return appSmsTemplate.getters.getIsButtonDisabled
    },
    lastCursorPosition() {
      return appSmsTemplate.getters.getLastCursorPosition
    },
  },
  async created() {
    await this.setSmsTemplate()
  },
  beforeDestroy() {
    appSmsTemplate.mutations.INIT_STATE()
  },
  methods: {
    async setSmsTemplate() {
      const smsTemplateID = this.$router.currentRoute.params.id
      if (smsTemplateID) {
        await appSmsTemplate.actions.fetchSmsTemplate(smsTemplateID)
      } else {
        appSmsTemplate.mutations.INIT_SMS_TEMPLATE()
      }
    },
    async saveChanges() {
      const smsTemplateID = this.$router.currentRoute.params.id
      if (smsTemplateID) {
        await appSmsTemplate.actions.updateSmsTemplate()
      } else {
        await appSmsTemplate.actions.createSmsTemplate()
      }
      this.$router.push({ name: 'admin-sms-templates' })
    },
    insertValueToEditor(val: string) {
      // Insert String at specific Index of another String
      const result = this.smsTemplate.body.slice(0, this.lastCursorPosition) + val + this.smsTemplate.body.slice(this.lastCursorPosition)
      this.smsTemplate.body = result
    },
    setCursorPosition(event: BvEvent) {
      appSmsTemplate.mutations.SET_LAST_CURSOR_POSITION(event.target.selectionEnd)
    },
    canSaveChanges() {
      const smsTemplateID = this.$router.currentRoute.params.id
      if (smsTemplateID) {
        return this.$can('update', permissionSubjects.SMSTemplate)
      }
      return true
    },
  },
})
